export const namePresets = {
    "Preset 1": {
        names: ['1 Canada', '2 Poland', '3 Finland', '4 Denmark', '5 Brazil', '6 Germany', '7 France', '8 United Kingdom', '9 Sweden', '10 Norway', '11 Spain', '12 Austria',
            '13 Turkey', '14 USA', '15 Portugal', '16 Argentina', '17 South Korea', '18 Japan', '19 Australia', '20 China', '21 Croatia', '22 Greece', '23 Ukraine', '24 Romania', '25 Bulgaria'],
        displayName: "Default preset",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 2": {
        names: ['1 Canada', '2 Poland', '3 Finland', '4 Denmark', '5 Brazil', '6 Germany', '7 France', '8 United Kingdom', '9 Sweden', '10 Norway', '11 Spain', '12 Austria',
            '13 Turkey', '14 USA', '15 Portugal', '16 Argentina', '17 South Korea', '18 Japan', '19 Australia', '20 China', '21 Croatia', '22 Greece', '23 Ukraine', '24 Romania', '25 Bulgaria'],
        displayName: "Scrims preset",
        defaultStart: 2,
        defaultNumTeams: 17,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 3": {
        names: ["stfu!", "WACE", "Bitfix Gaming", "KLANEN", "Valuable Veterans", "Unwanted", "Real Gangstas", "Slowstorm", "Behind Enemy Wives", "Formulation Gaming", "All Against Us", "For god for 3.div",
            "Perpetual Failure", "BootyMcYoloSwag", "Siklers", "Sami Gamers Black", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 1.div",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 4": {
        names: ['WACE BLUE', 'Den Gule Horde', 'Kjutta', 'PotetGull', 'stfu! OG\'s', 'Kjuagutter', '5STARS PUBG', 'NoobsWithBoobs', 'xc', 'Team Nordlys',
            'Cocacolaholihola', 'LeanNClean', 'Socially Disturbed?', 'Narnia Hippies', 'Grumpy Rage', 'Blaze Norge', "17", "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 2.div A",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 5": {
        names: ['NOTAG', 'Raufoss E-sport', 'ROCKBOYS PUBG', 'Ukjent Mix', 'Ishavsgeriljaen', 'Nubsquad', 'SG Boomerteam', 'Team-Buktu',
            '4 Alcoholics', 'Black Reapurrs', 'Roof Jumpers', 'TakTik', 'The Penetrators', 'stfu! 69\'ers', 'RYP', 'Mett i fletta', "17", "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 2.div B",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 6": {
        names: ['5Strategy', 'Spectral Esports', 'Glansbygda', 'MEMO', 'Rafsepatruljen', 'Los Pollos Hermanos', 'Knekken',
            'Tarashow PUBG', 'BBB', 'Beer Pressure', 'BetaFrost', 'What The Cluck', 'Ishavsgeriljaen Rek.', 'Grumpy LE', 'Søppelmenn PUBG', 'Noble-4 PUBG', '3+1', 'FoN', "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 3.div A",
        defaultStart: 0,
        defaultNumTeams: 18,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 7": {
        names: ['Pensjon Punch', 'The Retirement Home', 'Team Midlife Crisis', 'Atlantic 1742 E-sport', 'VÆD',
            'Grumpy II', 'Dolls with Sneiki Balls', 'Gaudergutenj', 'EGKT', 'Sävsjö Oldboys', 'Buskelusk', 'God Kok', 'Drippin\'Hot', 'Valhall *Valkyrie*', 'Capital Rangers', 'Dype Østerdalske S.', "17", "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 3.div B",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 8": {
        names: ['Image Clan', 'Grumpy I', 'Below Enemy Wives', 'Dolls with Balls', 'Foxed Gaming', 'Grumpy III', 'Trækkspællbataljon',
            '5STARS Red', 'Fragile Snakes', 'ACID', 'Team Knekken', 'BELLATOR', 'GuttaPåTur', 'Tactical Stocks', 'Valhall', 'Team Prim', 'Kjelleren til Ken', "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 3.div C",
        defaultStart: 0,
        defaultNumTeams: 17,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 9": {
        names: ['Alien Nation', 'Troublemakers', 'Mjolnir Esport I', 'dog', 'Simplify', 'Sami Gamers Smæsh', 'Fenderforeninga', 'Golden Cocks', '3600 PUBG',
            'DatasportFraWish', 'Inglorious Grumpy', 'Hitra Jaktlag', 'Dont Talking Please', 'PanzerWax', 'FerryMen', 'Oljepenger PUBG', 'Norse navigators', 'Mjolnir Esport II', 'Noble-6', "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 4.div",
        defaultStart: 0,
        defaultNumTeams: 19,
        alive: Array(25).fill(4),
        history: []
    },
};

export const newPresets = {
    "Preset 10": {
        names: ["Kjutta",
            "stfu! OG's",
            "Kjuagutter",
            "5STARS PUBG",
            "LeanNClean",
            "Team Nordlys",
            "SG Boomerteam",
            "Ishavsgeriljaen",
            "Team-Buktu",
            "Black Reapurrs",
            "Raufoss E-sport",
            "Ukjent Mix",
            "All Against Us",
            "Siklers",
            "Perpetual Failure",
            "For god for 3.div", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 1.div Qual",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 11": {
        names: ["Beer Pressure",
            "BetaFrost",
            "Knekken",
            "Glansbygda",
            "5Strategy",
            "MEMO",
            "VÆD",
            "Pensjon Punch",
            "Grumpy Old Bastards II",
            "Buskelusk",
            "The Retirement Home",
            "Fragile Snakes",
            "Grumpy Old Bastards I",
            "5STARS Red",
            "Trækkspællbataljon",
            "Dolls with Balls", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 2.div LB Qual",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    },
    "Preset 12": {
        names: ["NoobsWithBoobs",
            "Narnia Hippies",
            "WACE BLUE",
            "RYP",
            "Mett i fletta",
            "BBB",
            "Rafsepatruljen",
            "Drippin'Hot",
            "Team Midlife Crisis",
            "Image Clan",
            "Below Enemy Wives",
            "Pensjon Punch",
            "Glansbygda",
            "VÆD",
            "Fragile Snakes",
            "MEMO", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
        displayName: "GGL 2.div UB Qual",
        defaultStart: 0,
        defaultNumTeams: 16,
        alive: Array(25).fill(4),
        history: []
    }
};

export const countryCodes = [
    'CA', 'PL', 'FI', 'DK', 'BR', 'DE', 'FR', 'GB', 'SE', 'NO',
    'ES', 'AT', 'TR', 'US', 'PT', 'AR', 'KR', 'JP', 'AU', 'CN',
    'HR', 'GR', 'UA', 'RO', 'BG'
];

export const hexCodes = [
    '#0047A1', '#15931A', '#E16209', '#2096D1', '#4A148C', '#9F2B14', '#486A00',
    '#C61A56', '#9C6622', '#820045', '#D58115', '#4AB3AF', '#6B828D', '#F39700',
    '#37474F', '#E0648E', '#00736D', '#8A4803', '#7FB017', '#854BA1', '#38B27E',
    '#88ABDA', '#E58B65', '#2C276C', '#988E09'
];