import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { iconType, inputToggle, startTeamNr, TeamList, addTeam, removeTeam } from "./Functions";
import { faUser, faArrowDownAZ, faEye, faEyeSlash, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { namePresets, countryCodes, hexCodes, newPresets } from "./Values";

const Counter = ({ value, index, onDecrement, onIncrement, useFlags, hideInput, changeTeamname }) => {

    return (
        <div className="w-1/3 md:w-1/4 lg:w-1/5 mt-3 sm:mt-4 md:mt-3 md:mb-2 flex items-center justify-center flex-wrap flex-row self-start">

            <button className={"tracker-btn relative flex items-center border border-black sm:rounded-3xl p-3 md:px-4 shadow-xl bg-slate-800 hover:bg-slate-700 " + (useFlags && value.alive === 0 ? "bg-gray-800 grayscale-[85%] hover:bg-gray-800" : "")}
                onClick={() => {
                    onDecrement();
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    onIncrement();
                }}
            >
                {
                    useFlags ? (
                        <div>
                            <ReactCountryFlag
                                className="!w-[52px] sm:!w-[76px] xl:!w-[88px] 2xl:!w-[104px]"
                                countryCode={value.countrycode}
                                svg
                                style={{
                                    width: '52px',
                                    height: 'auto',
                                    display: 'inline-block'
                                }}
                                title={value.name}
                            />
                        </div>
                    ) : (
                        <div className={"border tracker-number border-black pt-1 text-2xl sm:text-3xl md:text-4xl bg-blue-500 w-[52px] sm:w-[76px] xl:w-[88px] 2xl:w-[104px] max-w-full h-[39px] sm:h-[57px] xl:h-[66px] 2xl:h-[78px] " + (value.alive === 0 ? "bg-zinc-800" : "")}
                            style={{ backgroundColor: value.hexcode && value.alive > 0 ? value.hexcode : undefined }}>
                            <div className="flex justify-center items-center h-full font-russo">{index + 1}</div>
                        </div>
                    )
                }
                <span className={"text-4xl md:text-5xl inline-block mr-2 " + (value.alive === 0 ? "text-gray-600" : "")}>{value.alive}</span>
            </button>
            {!hideInput &&
                <div>
                    <input name="teamname" maxLength={22} className="bg-transparent border-b mt-1 border-black text-sm sm:text-base md:text-lg xl:text-xl max-w-[110px] sm:max-w-[146px] md:max-w-[160px] xl:max-w-[172px] 2xl:max-w-[194px] text-gray-400"
                        onChange={changeTeamname}
                        value={value.name} type="text" />
                </div>
            }
        </div>
    );
};

const Counters = () => {

    // Load custom names from localStorage or use defaults
    const [presetConfigs, setPresetConfigs] = useState(() => {
        const savedConfigs = localStorage.getItem('presetConfigs');
        if (!savedConfigs) {
            const merged = { ...namePresets, ...newPresets };
            return merged;
        }
        const parsedConfigs = JSON.parse(savedConfigs);

        if (!parsedConfigs["Preset 12"]) {
            const merged = { ...parsedConfigs, ...newPresets };
            return merged;
        }

        // Ensure alive array exists and has correct length for each preset
        Object.keys(parsedConfigs).forEach(key => {
            const expectedLength = namePresets[key] ? namePresets[key].names.length : newPresets[key] ? newPresets[key].names.length : 0;

            if (!parsedConfigs[key].alive || parsedConfigs[key].alive.length !== expectedLength) {
                parsedConfigs[key].alive = Array(expectedLength).fill(4);
            }
            if (!parsedConfigs[key].history) {
                parsedConfigs[key].history = [];
            }
            if (!parsedConfigs[key].defaultNumTeams) {
                parsedConfigs[key].defaultNumTeams = 16;
            }
            if (typeof parsedConfigs[key].defaultStart !== 'number') {
                parsedConfigs[key].defaultStart = namePresets[key] ? namePresets[key].defaultStart : newPresets[key].defaultStart;
            }
        });
        return parsedConfigs;

    });

    const [selectedPreset, setSelectedPreset] = useState(() => {
        const savedPreset = localStorage.getItem('selectedPreset');
        return savedPreset || "Preset 1";
    });

    const getVisibleTeams = () => {
        const presetData = presetConfigs[selectedPreset];
        if (!presetData) return [];

        return presetData.names.slice(presetData.defaultStart, presetData.defaultStart + presetData.defaultNumTeams).map((name, index) => ({
            name,
            countrycode: countryCodes[presetData.defaultStart + index],
            alive: presetData.alive[presetData.defaultStart + index],
            hexcode: hexCodes[presetData.defaultStart + index]
        }));
    };

    const handlePresetChange = (newPreset) => {
        setSelectedPreset(newPreset);
    };

    const handleNameChange = (index, newName) => {
        const newConfigs = { ...presetConfigs };
        newConfigs[selectedPreset] = {
            ...newConfigs[selectedPreset],
            names: [...newConfigs[selectedPreset].names]
        };
        newConfigs[selectedPreset].names[index] = newName;
        setPresetConfigs(newConfigs);
    };

    const handleDecrement = (index) => {
        const currentPreset = presetConfigs[selectedPreset];
        if (!currentPreset || currentPreset.alive[actualIndex] <= 0) return;

        var actualIndex = currentPreset.defaultStart + index;

        const newConfigs = { ...presetConfigs };
        const previousCount = currentPreset.alive[actualIndex];

        if (newConfigs[selectedPreset].alive[actualIndex] > 0) {
            newConfigs[selectedPreset] = {
                ...currentPreset,
                alive: [...currentPreset.alive],
                history: [...currentPreset.history, { index: actualIndex, previousCount }]
            };
            newConfigs[selectedPreset].alive[actualIndex]--;
            setPresetConfigs(newConfigs);
        }
    }

    const handleIncrement = (index) => {
        const currentPreset = presetConfigs[selectedPreset];
        if (!currentPreset || currentPreset.alive[actualIndex] < 0) return;

        var actualIndex = currentPreset.defaultStart + index;

        const newConfigs = { ...presetConfigs };
        const previousCount = currentPreset.alive[actualIndex];

        if (newConfigs[selectedPreset].alive[actualIndex] < 4) {
            newConfigs[selectedPreset] = {
                ...currentPreset,
                alive: [...currentPreset.alive],
                history: [...currentPreset.history, { index: actualIndex, previousCount }]
            };
            newConfigs[selectedPreset].alive[actualIndex]++;
            setPresetConfigs(newConfigs);
        }
    }

    // const [startIndex, setStartIndex] = useState(() => {
    //     const currentPreset = presetConfigs[selectedPreset];
    //     if (!currentPreset || currentPreset.alive[actualIndex] <= 0) return;
    //     const savedStartIndex = localStorage.getItem('currentStartIndex');
    //     return savedStartIndex ? parseInt(savedStartIndex) : presetConfigs[selectedPreset]?.defaultStart || 0;
    // });

    const [numUkwPlayers, setNumUkwPlayers] = useState(() => {
        const savedUkwPlayers = localStorage.getItem('currentUkwPlayers');
        return savedUkwPlayers ? parseInt(savedUkwPlayers) : 0;
    });

    const [isSorted, setIsSorted] = useState(() => {
        const currentSort = JSON.parse(localStorage.getItem('sortedAlphabetical'));
        if (currentSort) {
            return true;
        }
        return false;
    });

    const visibleTeams = getVisibleTeams();

    const sortedTeams = isSorted
        ? [...visibleTeams].sort((a, b) => {
            if (!a || !b || !a.name || !b.name) return 0;
            return a.name.toString().localeCompare(b.name.toString(), undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        })
        : visibleTeams;
    const visibleUsers = sortedTeams.filter(team => team.alive > 0);
    const hiddenUsers = sortedTeams.filter(team => team.alive === 0);

    const getTeamCount = () => {
        return visibleTeams.filter((team) => team.alive > 0).length;
    };

    const getAliveCount = () => {
        return visibleTeams.reduce((total, team) => total + team.alive, 0) - numUkwPlayers;
    };

    const countTeamsWithNumAlive = (alive) => {
        return visibleTeams.filter(team => team.alive === parseInt(alive)).length;
    };

    const aliveTeams = getTeamCount();
    const alivePlayers = getAliveCount();
    const numSolos = countTeamsWithNumAlive(1);
    const numDuos = countTeamsWithNumAlive(2);
    const numThreeMan = countTeamsWithNumAlive(3);
    const numFullSquad = countTeamsWithNumAlive(4);

    const canUndo = presetConfigs[selectedPreset]?.history?.length > 0;
    const currentStartIndex = presetConfigs[selectedPreset]?.defaultStart || 0;

    const [useFlag, setFlag] = useState(() => {
        const disableFlag = JSON.parse(localStorage.getItem('disableFlag'));
        if (disableFlag) {
            return false;
        }
        return true;
    });

    const [hideInput, setHiddenInput] = useState(() => {
        const savedInput = JSON.parse(localStorage.getItem('hiddenInput'));
        if (savedInput) {
            return true;
        }
        return false;
    });

    const [showBar, setShowBar] = useState(() => {
        const hiddenBar = JSON.parse(localStorage.getItem('hiddenBar'));
        if (hiddenBar) {
            return false;
        }
        return true;
    });

    const [showLeftBar, setShowLeftBar] = useState(() => {
        const hiddenLeftBar = JSON.parse(localStorage.getItem('hiddenLeftBar'));
        if (hiddenLeftBar) {
            return false;
        }
        return true;
    });

    const handleUndo = () => {
        const currentPreset = presetConfigs[selectedPreset];
        if (!currentPreset || !currentPreset.history.length) return;

        const newConfigs = { ...presetConfigs };
        const lastAction = currentPreset.history[currentPreset.history.length - 1];

        newConfigs[selectedPreset] = {
            ...currentPreset,
            alive: [...currentPreset.alive],
            history: currentPreset.history.slice(0, -1)
        };
        newConfigs[selectedPreset].alive[lastAction.index] = lastAction.previousCount;
        setPresetConfigs(newConfigs);
    };

    const resetView = () => {
        const newConfigs = { ...presetConfigs };
        if (!newConfigs[selectedPreset]) return;

        const defaultConfig = namePresets[selectedPreset] ? namePresets[selectedPreset] : newPresets[selectedPreset];
        newConfigs[selectedPreset] = {
            ...newConfigs[selectedPreset],
            alive: Array(defaultConfig.names.length).fill(4),
            history: []
        };
        setPresetConfigs(newConfigs);
        setNumUkwPlayers(0);
    };

    // const resetTeams = () => {
    //     localStorage.removeItem('trackerData');
    //     setTeams(defaultTeams);
    // };

    function hideBar() {
        if (showBar) {
            setShowBar(false);
        }
        else {
            setShowBar(true);
        }
    }

    function IncreaseUkwPlayer() {
        if (numUkwPlayers < 9 && alivePlayers > 0) {
            setNumUkwPlayers(numUkwPlayers + 1);
        }
    }
    function DecreaseUkwPlayer() {
        if (numUkwPlayers > 0) {
            setNumUkwPlayers(numUkwPlayers - 1);
        }
    }

    useEffect(() => {
        localStorage.setItem('selectedPreset', selectedPreset);
        localStorage.setItem('presetConfigs', JSON.stringify(presetConfigs));
        localStorage.setItem('currentUkwPlayers', numUkwPlayers.toString());
        localStorage.setItem('disableFlag', !useFlag);
        localStorage.setItem('hiddenInput', hideInput);
        localStorage.setItem('hiddenBar', !showBar);
        localStorage.setItem('hiddenLeftBar', !showLeftBar);
        localStorage.setItem('sortedAlphabetical', isSorted);
    }, [selectedPreset, presetConfigs, numUkwPlayers, useFlag, hideInput, showBar, showLeftBar, isSorted]);

    return (
        <div>
            <div className="md:mb-2 border-b border-black">
                <div className="container mx-auto">
                    <div className="flex flex-row flex-wrap justify-center items-center mt-2 mb-2">
                        <div className="flex flex-row flex-wrap xl:ml-[242px]">
                            <p className="mx-4 border border-gray-400"><span className="bg-orange-700 text-white xs:text-2xl md:text-4xl p-2 inline-block">{alivePlayers}</span><span className="xs:text-2xl md:text-4xl py-2 px-3 inline-block">ALIVE</span></p>
                            <p className="mx-4 border border-gray-400"><span className="bg-lime-700 text-white xs:text-2xl md:text-4xl p-2 inline-block">{aliveTeams}</span><span className="xs:text-2xl md:text-4xl py-2 px-3 inline-block">TEAMS</span></p>
                        </div>
                        <div className="w-[242px] mt-2 sm:mt-0 pt-1 flex flex-wrap flex-row justify-between text-center text-lg md:text-xl text-gray-400">
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numSolos}</div>
                            </div>
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numDuos}</div>
                            </div>
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numThreeMan}</div>
                            </div>
                            <div className="px-2"><div><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faUser} /></div>
                                <div className="text-white">{numFullSquad}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap flex-row mb-10 relative wide-container mx-auto">
                <div className="w-auto pt-3">
                    <div className="hidden xl:block  pl-2">
                        <button onClick={() => setShowLeftBar(!showLeftBar)} className="mb-2 border border-black p-1 w-[40px] h-[40px] bg-slate-800 ">
                            {
                                showLeftBar ? (
                                    <>
                                        <FontAwesomeIcon icon={faEye} style={{ color: "#65a30d" }} width={22} />
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#9ca3af" }} width={22} />
                                    </>
                                )
                            }
                        </button>
                        <button onClick={() => setIsSorted(!isSorted)} className={"mb-2 ml-2 border border-black p-1 w-[40px] h-[40px] bg-slate-800" + (isSorted ? " text-lime-600" : " text-gray-400") + (showLeftBar ? "" : " hidden")}>
                            <FontAwesomeIcon icon={faArrowDownAZ} />
                        </button>
                    </div>
                    <div className={"w-auto hidden xl:block pl-2 teamlist " + (showLeftBar ? "" : "xl:hidden")}>
                        {visibleUsers.map((value, index) => {
                            return (
                                <TeamList value={value} index={index} key={"visible-" + currentStartIndex + index} />
                            )
                        })
                        }
                        {hiddenUsers.map((value, index) => {
                            return (
                                <TeamList value={value} index={index} key={"hidden-" + currentStartIndex + index} />
                            )
                        })
                        }
                    </div>
                </div>
                <div className="flex-1 mx-auto container">
                    <div className="flex flex-wrap flex-row px-4">
                        {visibleTeams.map((value, index) => {
                            return (
                                <Counter
                                    value={value}
                                    index={currentStartIndex + index}
                                    key={"counter-" + currentStartIndex + index}
                                    useFlags={useFlag}
                                    hideInput={hideInput}
                                    onDecrement={() => handleDecrement(index)}
                                    onIncrement={() => handleIncrement(index)}
                                    changeTeamname={(e) => handleNameChange(index + currentStartIndex, e.target.value)}
                                />
                            )
                        })
                        }
                        <div className="w-1/3 md:w-1/4 lg:w-1/5 mt-3 sm:mt-4 md:mt-3 md:mb-2 flex items-center justify-center flex-wrap flex-row">
                            <div className="unknown-btn justify-center flex items-center border border-black sm:rounded-3xl px-2 sm:px-3 shadow-xl h-[66px] sm:h-[83px] xl:h-[92px] 2xl:h-[104px]">
                                <button className={"px-2 bg-red-700 hover:bg-red-600 text-2xl md:text-4xl text-center min-w-[30px] h-[30px] leading-[30px] sm:min-w-[40px] sm:h-[40px] sm:leading-[40px] 2xl:min-w-[50px] 2xl:h-[50px] 2xl:leading-[50px] " + (numUkwPlayers === 0 ? "" : "")}
                                    onClick={() => {
                                        DecreaseUkwPlayer();
                                    }}
                                >&#x2212;</button>
                                <span className="text-2xl sm:text-4xl md:text-5xl inline-block mx-3 md:mx-4 xl:mx-6">{numUkwPlayers}</span>
                                <button className="px-2 bg-lime-700 hover:bg-lime-600 text-2xl md:text-4xl text-center min-w-[30px] h-[30px] leading-[30px] sm:min-w-[40px] sm:h-[40px] sm:leading-[40px] 2xl:min-w-[50px] 2xl:h-[50px] 2xl:leading-[50px]"
                                    onClick={() => {
                                        IncreaseUkwPlayer();
                                    }}
                                >&#x2b;</button>
                            </div>
                            <span className="mt-1 text-sm sm:text-base md:text-lg xl:text-xl text-gray-400 w-full text-center">Unknown death</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:fixed lg:bottom-0 w-full bg-zinc-900 pt-3 lg:pt-0 pb-1 lg:pb-0">
                <div className="container mx-auto relative">
                    <div className={"flex flex-row flex-wrap text-sm md:text-lg justify-center items-center px-4 " + (showBar ? "" : "hidden")}>

                        <a className="text-2xl font-russo mr-4 w-full my-3 lg:my-0 lg:w-auto text-center order-3 lg:order-1" href="https://pubgenie.gg/" target="_blank">PUBGenie</a>
                        {removeTeam(presetConfigs, selectedPreset, setPresetConfigs)}

                        {addTeam(presetConfigs, selectedPreset, setPresetConfigs)}

                        <div className="order-2 my-2 mx-1 md:mx-2 text-center md:w-auto">
                            {startTeamNr(presetConfigs, selectedPreset, setPresetConfigs)}
                        </div>

                        <div className="order-2 w-full md:w-auto flex flex-wrap flex-row justify-center">
                            {iconType(useFlag, setFlag)}

                            {inputToggle(hideInput, setHiddenInput)}

                            <select
                                value={selectedPreset}
                                onChange={(e) => handlePresetChange(e.target.value)}
                                className="border text-white flex items-center p-2 mx-1 md:mx-2 my-2 h-[42px] bg-zinc-900"
                            >
                                {Object.entries(presetConfigs).map(([key, config]) => (
                                    <option key={key} value={key}>{config.displayName}</option>
                                ))}
                            </select>
                            {/* <button className="border text-white p-2 flex items-center mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px]"
                                onClick={resetTeams}>
                                Reset teams
                            </button> */}

                            <button
                                onClick={handleUndo}
                                disabled={!canUndo}
                                className="border text-white flex items-center p-2 mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px] disabled:bg-gray-700 disabled:cursor-not-allowed"
                            >
                                <FontAwesomeIcon icon={faRotateLeft} /><span className='px-1'>Undo</span>
                            </button>

                            <button className="border text-white p-2 flex items-center mx-1 md:mx-2 my-2 bg-red-700 hover:bg-red-600 h-[42px]"
                                onClick={resetView}>
                                Reset
                            </button>
                        </div>
                    </div>

                    <button className="hidden lg:block absolute top-[-36px] left-1/2 right-1/2 -translate-x-1/2 500 text-white inline-block bg-slate-700 hover:bg-slate-800 w-[36px] h-[36px] flex items-center justify-center"
                        onClick={() => {
                            hideBar();
                        }}>
                        <span>&#8616;</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Counters;
