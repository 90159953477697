import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faEye, faEyeSlash, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

export const iconType = (useFlag, setFlag) => {
    return <button className="border flex items-center text-white p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800 h-[42px]"
        onClick={() => {
            if (!useFlag) {
                setFlag(true);
            }
            else {
                setFlag(false);
            }
        }}
    >
        {
            useFlag ? (
                <>
                    <FontAwesomeIcon icon={faEye} style={{ color: "#65a30d" }} width={22} /><span className='px-1'>Flags</span>
                </>
            ) : (
                <>
                    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#9ca3af" }} width={22} /><span className='px-1'>Flags</span>
                </>
            )
        }

    </button>;
}

export const inputToggle = (hideInput, setHiddenInput) => {
    return <button className="flex items-center h-[42px] border text-white p-2 inline-block mx-1 md:mx-2 my-2 hover:bg-slate-800"
        onClick={() => {
            if (!hideInput) {
                setHiddenInput(true);
            }
            else {
                setHiddenInput(false);
            }
        }}
    >
        {
            !hideInput ? (
                <>
                    <FontAwesomeIcon icon={faEye} style={{ color: "#65a30d" }} width={22} /><span className='px-1'>Teamname</span>
                </>
            ) : (
                <>
                    <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#9ca3af" }} width={22} /><span className='px-1'>Teamname</span>
                </>
            )
        }
    </button>;
}

export const startTeamNr = (presetConfigs, selectedPreset, setPresetConfigs) => {
    const newConfigs = { ...presetConfigs };
    const currentPreset = newConfigs[selectedPreset];    
    const newNumTeams = currentPreset.defaultNumTeams;
    const remainingSpace = currentPreset.names.length - currentPreset.defaultStart - 1;
 
    return <div className='inline-flex items-center border h-[42px]'>
        <button className={"text-white px-2 h-[40px] bg-amber-800 " + (currentPreset.defaultStart - 1 >= 0 ? "" : "pointer-events-none")}
            onClick={() => {
                const newStartIndex = currentPreset.defaultStart - 1;
                if (newStartIndex >= 0) {
                    currentPreset.defaultStart = newStartIndex;
                    setPresetConfigs(newConfigs);
                }
            }}
        >      <FontAwesomeIcon icon={faCaretLeft} /></button>
        <span className="ml-2 mr-2">Start slot #{currentPreset.defaultStart + 1}</span>
        <button className={"text-white px-2 h-[40px] bg-amber-800 " + (currentPreset.defaultNumTeams <= remainingSpace ? "" : "pointer-events-none")}
            onClick={() => {
                const newStartIndex = currentPreset.defaultStart + 1;
           
                if (newNumTeams <= remainingSpace) {
                    currentPreset.defaultStart = newStartIndex;
                    setPresetConfigs(newConfigs);
                }
            }}
        >
            <FontAwesomeIcon icon={faCaretRight} />
        </button>

    </div>;
}

export const removeTeam = (presetConfigs, selectedPreset, setPresetConfigs) => {
    return <button className="order-2 border text-white mx-1 md:mx-2 uppercase flex items-center my-2 group h-[42px]"
        onClick={() => {
            const newConfigs = { ...presetConfigs };
            const currentPreset = newConfigs[selectedPreset];
            const newNumTeams = currentPreset.defaultNumTeams - 1;
            const remainingSpace = currentPreset.names.length - currentPreset.defaultStart;

            if (newNumTeams >= 1 && newNumTeams <= remainingSpace) {
                newConfigs[selectedPreset] = {
                    ...currentPreset,
                    defaultNumTeams: newNumTeams
                };
                setPresetConfigs(newConfigs);
            }
        }}
    >
        <div className="h-full px-2 bg-red-700 group-hover:bg-red-600 border-r justify-center text-xl min-w-[40px] flex items-center"><FontAwesomeIcon icon={faMinus} /></div>
        <span className="p-2">team</span>
    </button>;
}

export const addTeam = (presetConfigs, selectedPreset, setPresetConfigs) => {
    return <button className="order-2 border text-white mx-1 md:mx-2 uppercase flex items-center my-2 group h-[42px]"
        onClick={() => {
            const newConfigs = { ...presetConfigs };
            const currentPreset = newConfigs[selectedPreset];
            const newNumTeams = currentPreset.defaultNumTeams + 1;
            const remainingSpace = currentPreset.names.length - currentPreset.defaultStart;

            if (newNumTeams >= 1 && newNumTeams <= remainingSpace) {
                newConfigs[selectedPreset] = {
                    ...currentPreset,
                    defaultNumTeams: newNumTeams
                };
                setPresetConfigs(newConfigs);
            }
        }}
    >
        <div className="h-full px-2 bg-lime-700 group-hover:bg-lime-600 border-r justify-center text-xl min-w-[40px] flex items-center"><FontAwesomeIcon icon={faPlus} /></div>
        <span className="p-2">team</span>
    </button>;
}


export const TeamList = ({ value }) => {

    return (
        <div className={"w-full flex flex-wrap flex-row justify-between space-between px-4 py-1 border-b border-l border-r border-black text-lg teamname " + (value.alive === 0 ? "text-gray-500 !bg-transparent" : "")}>
            <div className="">{value.name}</div>
            <div className="pl-6">{value.alive}</div>
        </div>
    )
}